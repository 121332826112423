import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import showDialogAction from '../../../actions/showDialogAction';
import OkCancelDialogContainer from '../../../components/dialogs/OkCancelDialogContainer';
import * as constants from '../../../constants';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import MultiSkuAltSkuDialogContainer from './dialogs/MultiSkuAltSkuDialogContainer';
import * as orderUtilities from './OrderProductScreenUtilities';
import SuggestedOrdersPanel from './SuggestedOrdersPanel';
import rpcAction from '../../../actions/rpcAction';
class SuggestedOrdersPanelContainer extends Component {
	render() {
		return (
			<SuggestedOrdersPanel {...this.props}
				disablePanel={this.props.disableSuggestedOrdersPanel}
			/>
		);
	}
}

const mapStateToProps = (_state, props) => {
	let resultsCount = 0 ;
	const orderLines = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], undefined);
	const excludeRatios = storeUtilities.getValue([props.screenId, 'excludeRatios']);
	const tab = storeUtilities.getValue([props.screenId, 'suggestedOrdersTabShown'], 'Orders');
	const selectAllChecked = storeUtilities.getValue([props.screenId, 'selectAll'], false);
	const excelDataSet = storeUtilities.getValue([props.screenId, 'excelDataSet'], undefined);
	const disableCalculatePOsButton = storeUtilities.getValue([props.screenId, 'totalOrderQty'], 0) === 0;
	const currentTab = storeUtilities.getValue([props.screenId, 'suggestedOrdersTabShown'], 'Orders');
	const disableDataDrivenButtons = currentTab !== 'Orders' && currentTab !== 'Details';
	const disableAddAltSkuButton = storeUtilities.getValue([props.screenId, 'suggestedOrdersOrdersGridSelectedRowsCount'], 0) === 0;
	if(orderLines && orderLines.length > 0) {
		resultsCount = orderLines.length;
	}
	return {
		excludeRatios,
		tab,
		disableSuggestedOrdersPanel: resultsCount === 0,
		selectAllChecked,
		excelDataSet,
		disableCalculatePOsButton,
		disableDataDrivenButtons,
		disableAddAltSkuButton
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			storeUtilities.updateValue(dispatch, [props.screenId, 'suggestedOrdersTabShown'], 'Orders');
		},
		onCalculatePOsClicked: (gridApi) => {
			storeUtilities.deleteValue(dispatch, [props.screenId, 'SuggestedPOs']);
			orderUtilities.updateOrderLinesInStore(dispatch, gridApi, [props.screenId, props.panelId, 'OrderLines']);
			const selectedCategory = storeUtilities.getValue([props.screenId, 'merchGroup'], '');
			switch(selectedCategory.toLowerCase()){
				case 'pillows':
				case 'case goods':
				case 'upholstery':
				case 'kids merchandise':
				case 'bedding':
				case 'isofa upholstery':
				case 'special order':
					getPurchaseOrdersCalc(dispatch, orderUtilities.getOrderLinesFromGrid(gridApi), props.screenId, props.panelId);
					break;
				case 'balanced ordering':
					getBalancedOrdersCalc(dispatch, (sku) => {
						return getQtyFromGrid(gridApi, sku); 
					}, props.screenId, props.panelId);
					break;
				default:
					sharedUtilities.showError('Invalid Selection', 'Category not recognized');
			}
		},
		onResetValuesClicked: () => {
			storeUtilities.updateValue(dispatch, [props.screenId, 'disableSuggestedPOsPanel'], true);
			const rowData = cloneDeep(storeUtilities.getValue([props.screenId, 'OrderLinesRowDataOriginal'], []));
			rowData.forEach((row)=>{
				const orderLine = orderUtilities.createOrderLineFromJsonResult(row);
				row['ORDERLINE'] = orderLine;
			});
			window.setTimeout(() => {
				storeUtilities.updateValue(dispatch, [props.screenId, 'OrderLinesRowData'], rowData);
				const { primaryOrderLine } = orderUtilities.getOrderLinesFromJsonArrayResults(rowData);
				storeUtilities.updateValue(dispatch, [props.screenId, 'PrimaryOrderLine'], primaryOrderLine);
				const sizeColumnsToFit = storeUtilities.getValue([props.screenId, 'sizeSuggestedOrdersOrdersTabGridColumnsToFit'], undefined);
				if (sizeColumnsToFit !== undefined) {
					sizeColumnsToFit();
				}
			}, 100);
		},
		getExcelDataSet: (ordersGrid, detailsGrid) => {
			const currentTab = storeUtilities.getValue([props.screenId, 'suggestedOrdersTabShown'], 'Orders');
			const gridRef = currentTab === 'Orders' ? ordersGrid : detailsGrid;
			const primaryNode = orderUtilities.getPrimaryNodeFromGrid(ordersGrid.api);
			const orderLinesRowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], []);
			const rowData = currentTab === 'Orders' 
				? orderUtilities.getSuggestedOrdersOrdersJsonRowData(props.screenId, orderLinesRowData, (primaryNode && primaryNode.rowIndex)? primaryNode.rowIndex : undefined) 
				: orderUtilities.getSuggestedOrdersDetailsJsonRowData(orderLinesRowData);
			const mustExcludedColumns = currentTab === 'Orders' ? ['ORDERLINE', 'STAR', 'ORIGINAL_ORDERLINE'] : [];
			if(rowData){
				return orderUtilities.getSuggestedOrdersGridExcelExportDataSet(gridRef, rowData, mustExcludedColumns, currentTab === 'Orders' ? {
					'Description': (row, colIdx) => row[colIdx]['detail']
				} : undefined);
			}
		},
		onTabChanged: (key) => {
			storeUtilities.updateValue(dispatch, [props.screenId, 'suggestedOrdersTabShown'], key);
		},
		onMaximizeCubeChanged: (maximizeCube) => {
			const selectedCategory = storeUtilities.getValue([props.screenId, 'merchGroup']);
			if(selectedCategory === 'Special Order' || selectedCategory === 'Pillows'){
				orderUtilities.handleSpecialOrderPillowsSelected(dispatch, props.screenId, maximizeCube, selectedCategory);
			}
		},
		onAddAltSkuClicked: (ordersGridRef, detailsGridRef) => {
			const selectedRows = ordersGridRef.api.getSelectedRows();
			const orderLines = Array.isArray(selectedRows) ? cloneDeep(selectedRows.map(row => row.ORDERLINE)) : [];
			const originalOrderLines = Array.isArray(selectedRows) ? cloneDeep(selectedRows.map(row => row.ORIGINAL_ORDERLINE)) : [];
			const whsNumInUse = storeUtilities.getValue([props.screenId, 'whsNumInUse'], undefined);
			const orderLinesRowData = storeUtilities.getValue([props.screenId, 'OrderLinesRowData'], undefined);
			dispatch(showDialogAction(
				<MultiSkuAltSkuDialogContainer 
					screenId={props.screenId}
					panelId={'MultiSkuAltSkuDialog'}
					parentPanelId={props.panelId}
					orderLines={orderLines}
					originalOrderLines={originalOrderLines}
					path={[props.screenId, 'MultiSkuAltSkuDialog']}
					skuAltSkuMappingPath={[props.screenId, 'skuAltSkuMapping', whsNumInUse]}
					onSkuTypeChanged={(orderValue, orderLine, altOrderLine, altSkuType) =>{ 
						orderUtilities.handleOrderChanged({
							dispatch,
							path: [props.screenId],
							newValue: orderValue,
							orderLine,
							altOrderLine,
							altSkuType,
							callback: () => {
								orderUtilities.refreshSuggestedOrdersGrids({ordersGridApi: ordersGridRef?.api, detailsGridApi: detailsGridRef?.api, excludeRatios: true, changedRowNode: orderUtilities.getNode(ordersGridRef.api, orderLine.SKU)});
								orderUtilities.calculateAndUpdateTotalOrderQty(dispatch, [props.screenId], ordersGridRef.api);
							},
							orderLinesRowData
						});
					}}
				/>, 300, 1000, undefined, true, undefined, true, false, true, 'MultiSkuAltSkuDialog'));
		},
		handleOrdersGridRowClicked: (params) => {
			const selectedRows = params.api.getSelectedRows();
			const count = selectedRows.length;
			storeUtilities.updateValue(dispatch, [props.screenId, 'suggestedOrdersOrdersGridSelectedRowsCount'], count);
	
			const rowNodes = [];
			params.api.forEachNode(node => rowNodes.push(node));
			params.api.refreshCells({rowNodes: rowNodes, columns: ['SKU'], force:true});
		},
		onExportToExcelClicked: (dataset) => {
			orderUtilities.exportToExcelClicked(dispatch, props.screenId, dataset);
		}
	};
};
const isEmpty = (val) => {
	return (val === undefined || (val.trim && val.trim() === ''));
};
const poAlphaNumericComparator = (po1, po2) => {
	let returnVal = 0;
	if(po1 !== undefined && po2 !== undefined){
		if(!isEmpty(po1.group) && !isEmpty(po2.group)){
			if(po1.group === po2.group){
				returnVal = 0;
			}
			else if (po1.group < po2.group){
				returnVal = -1;
			}
			else {
				returnVal = 1;
			}
		}
		else if(!isEmpty(po1.group) && isEmpty(po2.group)){
			returnVal = 1;
		}
		else if(isEmpty(po1.group) && !isEmpty(po2.group)){
			returnVal = -1;
		}
		else {
			returnVal = 1;
		}
	}
	else { 
		returnVal = 1; 
	}
	return returnVal;
};
export const getPurchaseOrdersCalc = (dispatch, orderLines, screenId, panelId) => {
	const useLoadFactor = storeUtilities.getValue([screenId, 'useLoadFactor'], false);
	const usePDMPieceCountInUse = storeUtilities.getValue([screenId, 'usePDMPieceCountInUse'], false);
	const maximizeCube = storeUtilities.getValue([screenId, 'maximizeCube'], false);
	const groupPOsBy = storeUtilities.getValue([screenId, 'groupPOsBy'], undefined);
	const maxPO = storeUtilities.getValue([screenId,'maxPO'], 0);
	const maxPerWeek = storeUtilities.getValue([screenId, 'maxPerWeek'], 0);
	const maxPoQty = maxPO <= maxPerWeek ? maxPO : maxPerWeek;
	const req =
		{
			vendor: storeUtilities.getValue([screenId,'vendor'], ''),
			maximizeCube,
			skus: [],
			productGroup: storeUtilities.getValue([screenId,'merchGroup'], ''),
			sizes:{
				maxPoQty,
				poCube: storeUtilities.getValue([screenId,'poCube'], 0)
			},
			daysTillFilled: {
				prodLeadTime: storeUtilities.getValue([screenId,'prodTime'], 0),
				transitLeadTime: storeUtilities.getValue([screenId,'transitTime'], 0),
				daysToNextOrder: storeUtilities.getValue([screenId,'daysToNextOrder'], 0),
				additionalDaysOfStock: storeUtilities.getValue([screenId,'daysToStock'], 0),
				total: storeUtilities.getValue([screenId,'txtTotalDays'], 0)
			},
			adjustments: {
				salesPerct: storeUtilities.getValue([screenId,'marketAdjustment'], 0),
				poAdj: storeUtilities.getValue([screenId,'POAdjustment'], 0)
			},
			inclusions: {
				incInterdivisionalOrders: storeUtilities.getValue([screenId,'intraOrder'], false),
				excludPromoAvailable: storeUtilities.getValue([screenId,'showPromoAvail'], false)
			}
		};
	let requestCount = 0;
	let responseCount = 0;
	const errors = [];
	const purchaseOrders = [];
	const productPOCalcCallback = (data, groupName) => {
		responseCount++;
		if(data !== undefined){
			if(Array.isArray(data.Errors)){
				data.Errors.forEach(error => { 
					errors.push(error);
				});
			}
			data.PurchaseOrders.sort(getShipDateComparator('MM/DD/YYYY'));
			data.PurchaseOrders.forEach(po => {
				po['group'] = groupName;
				purchaseOrders.push(po);
			});
		}
		else {
			errors.push(
				{
					Message: 'No data returned', 
					Detail: 'No data returned by calculate POs call' + (groupName !== undefined && groupName.trim && groupName.trim() !== '' ? (' for Group \'' + groupName + '\'') : '')
				});
		}

		if(requestCount === responseCount){
			if(errors.length > 0){
				let i = 0;
				const errs = errors.map(error => {
					return(
						<li key={i++} style={{paddingBottom: '5px'}}>
							<b>
								<u>
									{error.Message}
								</u>
							</b>
							<br />
							<div style={{paddingTop:'3px'}}>
								{error.Detail}
							</div>
						</li>);
				});
				dispatch(showDialogAction(
					<OkCancelDialogContainer 
						screenId={screenId + '_informationDialog'} 
						title={'Information'}
						message={'Calculate POs Errors'}>
						<ul>{errs}</ul>
					</OkCancelDialogContainer>, 
					250, 
					400, 
					()=>{}));
			}
			else if (purchaseOrders.length > 0){
				const poSkus = buildSuggestedPOs(purchaseOrders.sort(poAlphaNumericComparator));
				const orderLines = storeUtilities.getValue([screenId, panelId, 'OrderLines']);
				poSkus.forEach(poSku=>{ insertOrderLine(poSku, orderLines); });
				storeUtilities.updateValue(dispatch, [screenId, 'disableSuggestedPOsPanel'], false);
				const whs = storeUtilities.getValue([screenId, 'whsNum'], undefined);
				const poSkusWithCorrectedDates = orderUtilities.setDatesBasedOnMaxPerWeek(screenId, poSkus);
				mapRowDataToSuggestedPOs(dispatch, screenId, whs, poSkusWithCorrectedDates);
			}
			storeUtilities.updateGlobalAppStateValue(dispatch, ['showLoadingMask'], false);
		}
	};
	const groups = {};
	const groupPOsByKeys = groupPOsBy === undefined ? [] : (groupPOsBy.split ? groupPOsBy.split('|') : []);
	for(let i = 0; i < orderLines.length; i++){
		const orderLine = orderLines[i];
		const groupKey = getGroupingKeyFromObject(groupPOsByKeys, orderLine);
		const groupKeys = Object.keys(groups);
		if(!groupKeys.includes(groupKey)){
			groups[groupKey] = [];
		}
		const { altSkuType } = orderUtilities.getAltSkuInfo(screenId, orderLine.SKU);
		const ol = cloneDeep(orderLine);
		ol.needByDaysSKUType = altSkuType;
		groups[groupKey].push(ol);
	}
	const entries = Object.entries(groups);
	entries.forEach(([groupName, group]) => {
		if(Array.isArray(group)){
			let totalOrderQty = 0;
			const reqClone = cloneDeep(req);
			group.forEach(orderLine => {
				const skuObj = getSkuObjForProductPOCalculation(orderLine, useLoadFactor, usePDMPieceCountInUse);
				totalOrderQty += parseInt(skuObj.quantity) > 0 ? parseInt(skuObj.quantity) : 0;
				reqClone.skus.push(skuObj);
			});
			if(totalOrderQty > 0){
				requestCount++;
				doCalculation(dispatch, reqClone, groupName, productPOCalcCallback, totalOrderQty, constants.nodeRoutes.POCalculateService, 'calculate');
			}
		}
	});
};
const getGroupingKeyFromObject = (keys, obj) => {
	const uppercasedObj = sharedUtilities.uppercaseObjectKeys(obj);
	if(Array.isArray(keys)){
		let returnVal = '';
		keys.forEach(key => {
			const val = uppercasedObj[key.toUpperCase()] === undefined ? '' : uppercasedObj[key.toUpperCase()];
			if(returnVal.length === 0){
				returnVal += val;
			}
			else {
				returnVal += '/' + val;
			}
		});
		return returnVal;
	}
	return '';
};
const getSkuObjForProductPOCalculation = (orderLine, useLoadFactor, usePDMPieceCountInUse) => {
	const pieceCount = isNaN(orderLine.PieceCount) || parseInt(orderLine.PieceCount) < 1 ? 1 : parseInt(orderLine.PieceCount);
	const pdmPieceCount = isNaN(orderLine.PDMPieceCount) || parseInt(orderLine.PDMPieceCount) < 1 ? 1 : parseInt(orderLine.PDMPieceCount);
	const sku = {
		addDaysToStock: orderLine.ADTS,
		addedByMaximize: false,
		adjustedQty: '',
		altQty: '',
		available: orderLine.LTAVAIL,
		availToday: orderLine.BVAVAIL,
		avgDailySales: orderLine.DailyMean,
		availableAfterOrder: orderLine.AvailableAfterOrder,
		balance: '',
		cube: useLoadFactor === true ? orderLine.LoadFactor : orderLine.Cube,
		daysToNextOrder: orderLine.DaysToNextOrder,
		endLeadTimeAvailable: orderLine.EOLAVAIL,
		family: orderLine.Family,
		inbound: orderLine.BVINBOUND,
		index: '',
		intradivisional: orderLine.IntraDivisional,
		maximizeQuantity: '',
		needByDays: 0,
		needByDaysSKUType: orderLine.needByDaysSKUType,
		orderQty: '',
		orderRatio: orderLine.Ratio,
		originalAvailable: 0,
		originalAvailableSet: false,
		pieceCount: usePDMPieceCountInUse === true ? pdmPieceCount : pieceCount,
		promoAvail: orderLine.PromoAvail,
		proportionsNegative: '',
		proportionsZero: '',
		qoh: orderLine.QOH,
		qtyFromWhs: orderLine.QtyFromWHS,
		qtyToWhs: orderLine.QtyToWHS,
		quantity: orderLine.Order,
		remaining: '',
		REPLACEMENTSKU: orderLine.REPLACEMENTSKU || '',
		replenishmentStock: orderLine.ReplenishmentStock,
		safetyStock: orderLine.SafetyStock,
		sku: orderLine.SKU,
		standardDeviation: orderLine.DailyStdDev,
		stockedThroughDate: '',
		storeQuantityOnHand: orderLine.STOREQTY,
		totalSales: orderLine.TotalSales,
		trigger: orderLine.Trigger,
		weeksOfStockOnHand: orderLine.WOSOH,
		weeksOfStock: orderLine.WeeksOfStock,
		weeklySales: orderLine.WeeklySales
	};
	return sku;
};
const doCalculation = (dispatch, req, groupName, callback, totalOrderQty, nodeRoute, method) => {
	if(totalOrderQty <= 0){
		return;
	}
	dispatch(rpcAction({
		args: req,
		nodeRoute: nodeRoute,
		endpoint: method,
		method: 'POST',
		showLoadingMask: true,
		hideLoadingMaskOnComplete: false,
		headers: { 'Content-Type': 'application/json' },
		callback: (data) => {
			if(callback !== undefined){
				callback(data, groupName);
			}
		},
		customResponseHandler: (response) => {	
			return sharedUtilities.parseResponse(response);
		},
		retryOnFailure: true
	}));
};

const buildSuggestedPOs = (purchaseOrders) => {
	let poIdx = 0;
	let skuIdx = 0;
	let pos = [];
	for(poIdx = 0; poIdx < purchaseOrders.length; poIdx++ ){
		for(skuIdx = 0; skuIdx < purchaseOrders[poIdx].skus.length; skuIdx++){
			pos.push({
				PO: poIdx+1,
				SKU: purchaseOrders[poIdx].skus[skuIdx].sku,
				QTY: purchaseOrders[poIdx].skus[skuIdx].quantity,
				CUBES: purchaseOrders[poIdx].cubeTotal,
				SHIPDATE: purchaseOrders[poIdx].shipDate,
				ARRIVALDATE: purchaseOrders[poIdx].arrivalDate,
				NEEDBYDATE: moment(purchaseOrders[poIdx].skus[skuIdx].stockedThroughDate, 'MM/DD/YYYY').format(constants.cfQueryDateFormat),
				GROUP: purchaseOrders[poIdx].group
			});
		}
	}
	return pos;
};
const getQtyFromGrid = (gridApi, sku) => {
	let qty = 0;
	gridApi.forEachNode(node => {
		if(node.data.ORDERLINE.SKU === sku){
			qty = parseInt(node.data.ORDERLINE.Order);
		}
	});
	return parseInt(qty);
};

const getShipDateComparator = (dateFormat) => {
	return (a, b)=>{
		const aShipMoment = moment(a.shipDate, dateFormat);
		const bShipMoment = moment(b.shipDate, dateFormat);
		const result = aShipMoment.isAfter(bShipMoment) ? 1 : (aShipMoment.isBefore(bShipMoment) ? -1 : 0);
		return result;
	};
};

export const getBalancedOrdersCalc = (dispatch, fnGetSKUQtyFromGrid, screenId, panelId) => {
	storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'totalCost'], 0, true);//reset totalCost
	const usePDMPieceCountInUse = storeUtilities.getValue([screenId, 'usePDMPieceCountInUse'], false);
	const groupPOsBy = storeUtilities.getValue([screenId, 'groupPOsBy'], undefined);
	const whs = storeUtilities.getValue([screenId, 'whsNum'], undefined);
	const req = {
		cubespercontainer: storeUtilities.getValue([screenId, 'poCube'], 0),
		productionleadtime: storeUtilities.getValue([screenId, 'prodTime'], 0),
		transitleadtime: storeUtilities.getValue([screenId, 'transitTime'], 0),
		daystonextorder: storeUtilities.getValue([screenId, 'daysToNextOrder'], 0),
		additionaldaystostock: storeUtilities.getValue([screenId, 'daysToStock'], 0),
		maximizepo: storeUtilities.getValue([screenId, 'maximizeCube'], false) ? true : false,
		skus: []
	};
	const skus = [];
	const orderLinesRowData = storeUtilities.getValue([screenId, 'OrderLinesRowData'], undefined);
	const orderLinesRowDataOriginal = storeUtilities.getValue([screenId, 'OrderLinesRowDataOriginal'], []);
	const { orderLines } = orderUtilities.getOrderLinesFromJsonArrayResults(orderLinesRowData !== undefined ? orderLinesRowData : orderLinesRowDataOriginal);
	let requestCount = 0;
	let responseCount = 0;
	const purchaseOrders = [];
	const balancedOrdersCalcCallback = (data, groupName) => {
		responseCount++;
		const pos = {};
		if(Array.isArray(data)){
			data.sort(getShipDateComparator(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS));
			for(let i = 0; i < data.length; i++){
				const row = data[i];
				const posKeys = Object.keys(pos);
				const poId = String(row.po);
				const sku = String(row.sku);
				const arrivalDate = moment(row.arrivalDate, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
				const shipDate = moment(row.shipDate, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
				if(!posKeys.includes(poId)){
					pos[poId] = {
						arrivalDate,
						shipDate,
						skus: [],
						cubeTotal: 0,
						qty: 0,
						group: groupName
					};
				}
				pos[poId].skus.push({
					sku,
					needByDate: moment(row.needByDate).format('MM/DD/YYYY'),
					quantity: row.qty,
					cubes: row.cubes,
					stockedThroughDate: moment(row.needByDate).format('MM/DD/YYYY')
				});
				pos[poId].cubeTotal += row.cubes;
				pos[poId].qty += row.qty;
			}
			const keysInGroup = Object.keys(pos);
			keysInGroup.forEach(key => {
				purchaseOrders.push(pos[key]);
			});
		}
		if(requestCount === responseCount){
			const poSkus = buildSuggestedPOs(purchaseOrders.sort(poAlphaNumericComparator));
			const orderLines = storeUtilities.getValue([screenId, panelId, 'OrderLines']);
			poSkus.forEach(poSku=>{ insertOrderLine(poSku, orderLines); });
			storeUtilities.updateValue(dispatch, [screenId, 'disableSuggestedPOsPanel'], false);
			storeUtilities.updateGlobalAppStateValue(dispatch, ['showLoadingMask'], false);
			const poSkusWithCorrectedDates = orderUtilities.setDatesBasedOnMaxPerWeek(screenId, poSkus);
			mapRowDataToSuggestedPOs(dispatch, screenId, whs, poSkusWithCorrectedDates);
		}
	};
	for(let orderLinesIndex = 0; orderLinesIndex < orderLines.length; orderLinesIndex++){
		const orderLine = cloneDeep(orderLines[orderLinesIndex]);
		const {altSkuType} = orderUtilities.getAltSkuInfo(screenId, orderLine.SKU);
		orderLine.needByDaysSKUType = altSkuType;
		const qty = fnGetSKUQtyFromGrid(orderLine.SKU);
		if(qty > 0){
			skus.push({
				sku: orderLine.SKU,
				cube: orderLine.Cube,
				qty,
				ads: orderLine.DailyMean,
				bla: orderLine.EOLAVAIL,
				pieceCount: usePDMPieceCountInUse === true ? orderLine.PDMPieceCount : orderLine.PieceCount,
				skuGroup: orderLine.SkuGroup,
				factoryColor: orderLine.FactoryColor,
				theme: orderLine.Theme
			});
		}
	}
	const groups = {};
	const groupPOsByKeys = groupPOsBy === undefined ? [] : (groupPOsBy.split ? groupPOsBy.split('|') : []);
	skus.forEach(sku => {
		const groupKey = getGroupingKeyFromObject(groupPOsByKeys, sku);
		const groupKeys = Object.keys(groups);
		if(!groupKeys.includes(groupKey)){
			groups[groupKey] = [];
		}
		groups[groupKey].push(sku);
	});
	const entries = Object.entries(groups);
	entries.forEach(([groupKey, group]) => {
		if(Array.isArray(group)){
			let totalOrderQty = 0;
			const reqClone = cloneDeep(req);
			group.forEach(skuObj => {
				totalOrderQty += skuObj.qty > 0 ? skuObj.qty : 0;
				reqClone.skus.push(skuObj);
			});
			if(totalOrderQty > 0){
				requestCount++;
				doCalculation(dispatch, reqClone, groupKey, balancedOrdersCalcCallback, totalOrderQty, constants.nodeRoutes.IFRServiceNET, 'Orders/BalancedLoad');
			}
		}
	});
};

const insertOrderLine = (row, orderLines) => {
	const filteredOrderLines = orderLines.filter(x => x.SKU === row.SKU);
	const orderLine = filteredOrderLines.length === 1 ? filteredOrderLines[0] : undefined;
	if(orderLine !== undefined) { 
		row['ORDERLINE'] = orderLine; 
	}
};

/**
 * For each data for suggested POs from calculate POs or balanced ordering, map that datarow into PO in Redux store expected format: "SuggestedPOs.PurchaseOrders"
 * @param {Object} dispatch - redux dispatch Object
 * @param {string} screenId - Screen ID where this component will appear
 * @param {string} whs - the whs for the current data
 * @param {Array} data - Array of Purchase Orders
 */
const mapRowDataToSuggestedPOs = (dispatch, screenId, whs, data) => {
	if(Array.isArray(data) === false){
		return;
	}
	const useLoadFactor = storeUtilities.getValue([screenId, 'useLoadFactor'], false);
	const useCubes = orderUtilities.useCubes(screenId);
	const cubesLimit = storeUtilities.getValue([screenId, 'poCube'], 0);
	const selectedCategory = storeUtilities.getValue([screenId, 'merchGroup'], '');
	const isBalancedOrdering = selectedCategory === 'Balanced Ordering';

	const whsPOs = {};
	whsPOs[whs] = {};
	for(let dataIndex = 0; dataIndex < data.length; dataIndex++){
		const poSku = data[dataIndex];
		const obj = {};
		Object.assign(obj, poSku);
		if(whsPOs[whs][poSku.PO] === undefined){
			whsPOs[whs][poSku.PO] = [obj];
		}
		else{
			whsPOs[whs][poSku.PO].push(poSku);
		}
	}
	let totalCost = storeUtilities.getValue([screenId, 'SuggestedPOs', 'totalCost'], 0);
	for(const [warehouse, POs] of Object.entries(whsPOs)){
		for(const [poNum, rowData] of Object.entries(POs)){
			const poPath = getPOPath(screenId, warehouse, parseInt(poNum));
			let cubes = 0;
			let poTotalCost = 0;
			let shipDate = undefined;
			let arrivalDate = undefined;
			let group = undefined;
			if(Array.isArray(rowData)){
				for(let rowIndex = 0; rowIndex < rowData.length; rowIndex++){
					const row = rowData[rowIndex];
					shipDate = (isBalancedOrdering ? row.SHIPDATE : moment(row.SHIPDATE, 'MM/DD/YYYY')).toDate();
					arrivalDate = (isBalancedOrdering ? row.ARRIVALDATE : moment(row.ARRIVALDATE, 'MM/DD/YYYY')).toDate();
					const cubesCalcLine = row.QTY * (useLoadFactor === true ? row.ORDERLINE.LoadFactor : row.ORDERLINE.Cube);
					cubes += cubesCalcLine;
					row.FIRSTCOST = row.ORDERLINE.FIRSTCOST;
					row.LINE = row.QTY * row.FIRSTCOST;
					poTotalCost += row.LINE;
					group = row.GROUP;
				}
			}
			//the balanced ordering calculation returns the dates in a different format
			const poObj = {
				poNumber: poNum,
				deleted: false,
				shipDate,
				arrivalDate,
				deliverTo: warehouse,
				cubes,
				rowData,
				totalCost: poTotalCost,
				cubesOverLimit: useCubes ? cubes > cubesLimit : false,
				group
			};
			storeUtilities.updateValue(dispatch, [...poPath], poObj, true);
			totalCost += poTotalCost;
		}
	}
	storeUtilities.updateValue(dispatch, [screenId, 'SuggestedPOs', 'totalCost'], totalCost, true);
};
const getPOPath = (screenId, whsNum=undefined, poNum) => {
	const path = whsNum === undefined ? [screenId, 'SuggestedPOs', 'purchaseOrders', poNum] : [screenId, 'SuggestedPOs', 'purchaseOrders', whsNum, poNum];
	return path;
};
SuggestedOrdersPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	divisions: PropTypes.arrayOf(PropTypes.string),
	onMaximizeCubeChanged: PropTypes.func,
	doNotMonitorSize: PropTypes.any
};
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SuggestedOrdersPanelContainer);
export default connectedComponent;