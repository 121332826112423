import { RPC_ACTION } from '../actionTypes';

/**
 * function rpcAction
 * @constructor
 * @param {object} 			options.args - JSON object or Immutable.Map representing the arguments to be sent to the cfc
 * @param {string} 			options.nodeRoute - route in the node back-end (app.js in ServerJS). Might be the path of the cfc (examples: 'ifr' or 'components/calcProdPos/Controller') or the Service Mapping of a REST API (like rest/productPO)
 * @param {string} 			options.endpoint - path of the function to be called in the cfc 
 * @param {function} 		options.callback - callback after rpc is executed
 * @param {boolean} 		options.showLoadingMask - indicates whether or not to show the loading mask while the call is being made
 * @param {string} 			options.method - GET, POST, PUT, DELETE
 * @param {boolean} 		options.hideLoadingMaskOnComplete - set to allows multiple successive data calls to prevent the screen darkening and lightening for every individiual data call
 * @param {function} 		options.fnOnCatch - allows for additional error handling, if needed
 * @param {function} 		options.fnOnFinally - allows for any cleanup that must occur whether or not the call succeeds
 * @param {object} 			options.headers - override headers if application/json is not what is needed
 * @param {function} 		options.customResponseHandler - as name suggests...overrides response handling logic in api.js
 * @param {boolean}			options.requestInBody - indicates whether or not to send the request in the body of the request (as opposed to the URL)
 * @param {boolean} 		options.retryOnFailure - indicates whether or not to retry the call if it fails
 * @param {boolean}			options.preventDuplicateRequests - indicates whether or not duplicate requests should be allowed
*/
const rpcAction = ({
	args, 
	nodeRoute, 
	endpoint, 
	callback=()=>{}, 
	showLoadingMask=true, 
	method='GET', 
	hideLoadingMaskOnComplete=true, 
	fnOnCatch=()=>{}, 
	fnOnFinally=()=>{},
	headers,
	customResponseHandler, 
	requestInBody, 
	retryOnFailure=true,
	preventDuplicateRequests=false
}) => {
	return {
		type: RPC_ACTION,
		nodeRoute,
		endpoint,
		args,
		callback,
		showLoadingMask,
		method,
		hideLoadingMaskOnComplete,
		fnOnCatch,
		fnOnFinally,
		headers,
		customResponseHandler,
		requestInBody,
		retryOnFailure,
		preventDuplicateRequests
	};
};
export default rpcAction;